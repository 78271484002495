import { NoResultsSVG } from "src/assets";
import {
  HeadingSubtitleStyled,
  LargeHeadingStyled,
  SectionFiltersHeaderStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "src/components/custom/texts/texts.styled";
import { FlexStyled } from "src/components/registration/register-introduction/register-introduction.styled";
import { IActivity, IStudentGrades } from "@/Interfaces/teacherCourses";
import { Box, Icon } from "@nubeteck/components";
import { Button, Card, Form, Image, Input, Modal, Segmented } from "antd";
import { Content } from "antd/es/layout/layout";
import Table, { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { SegmentedValue } from "antd/es/segmented";
import { ColumnContainer } from "src/components/layout/main-layout/main-layout.styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDownloadGradesByCourseMutation,
  useGetGradesByCourseMutation,
  useUpdateGradesByCourseMutation,
} from "src/services/gradesServices";
import { editableComponents } from "src/components/custom/editable-table-items/editable-table-items";
import { ButtonAlternativeStyled } from "src/components/custom/buttons/buttons.styled";
import GradesPDFPreview from "src/components/other/grades-pdf/gradesPdf";
import { downloadExcelBase64 } from "src/utils/files-util";

function CourseGrades() {
  const { seccionId } = useParams();
  const navigate = useNavigate();
  const [getGrades, { data, isLoading }] = useGetGradesByCourseMutation();
  const [tipoCalificacion, setTipoCalificacion] = React.useState<SegmentedValue>(1);
  const [isInputTouched, setIsInputTouched] = useState(false);

  const [modal, contextHolder] = Modal.useModal();
  const [saveGrades, { isLoading: isLoadingSaving, isSuccess, error }] =
    useUpdateGradesByCourseMutation();
  const [downloadGrades, { isLoading: isLoadingGradesFile, data: gradesFile }] =
    useDownloadGradesByCourseMutation();

  const [estudianteNombre, setEstudianteNombre] = useState<string>("");
  const [calificaciones, setCalificaciones] = useState<any[]>([]);
  const [modalOpened, setModalOpened] = useState(false);

  const obtenerCalificaciones = useCallback(() => {
    getGrades({ seccionId, estudianteNombre, isScaled: tipoCalificacion === 1 });
  }, [estudianteNombre, getGrades, seccionId, tipoCalificacion]);

  useEffect(() => {
    obtenerCalificaciones();
  }, [obtenerCalificaciones]);

  useEffect(() => {
    setCalificaciones(data?.estudiantes);
  }, [data]);

  useEffect(() => {
    if (error) toast.error(error?.data?.detail, toastErrorStyle);
    if (isSuccess) {
      toast.success("Calificaciones actualizadas correctamente", toastSuccessStyle);
      obtenerCalificaciones();
      setIsInputTouched(false);
    }
  }, [error, isSuccess, obtenerCalificaciones]);

  useEffect(() => {
    if (gradesFile) {
      downloadExcelBase64(`Calificaciones del curso`, gradesFile);
    }
  }, [gradesFile]);

  const defaultColumns = React.useMemo<ColumnsType<any>>(
    () =>
      [
        {
          title: "Estudiante",
          dataIndex: "estudianteNombre",
          editable: false,
          fieldType: "text",
          width: 300,
        },
      ]
        .concat(
          data?.estudiantes[0]?.calificaciones?.map((cal: IActivity) => ({
            title: `${cal.actividadNombre} (${
              tipoCalificacion !== 1 ? cal.puntosPosibles : "100"
            } pts.)`,
            dataIndex: cal.actividadId,
            editable: true,
            fieldType: "grade-feedback",
            width: cal.actividadNombre.length * 8 + 50,
          })),
        )
        .concat([
          {
            title: "Porcentaje de asistencia",
            dataIndex: "porcentajeAsistencia",
            editable: false,
            fieldType: "text",
            width: 100,
          },
          {
            title: "Total acumulado",
            dataIndex: "totalAcumulado",
            editable: false,
            fieldType: "text",
            width: 100,
          },
          {
            title: "Proyección",
            dataIndex: "totalProyectado",
            editable: false,
            fieldType: "text",
            width: 100,
          },
        ]),

    [data, tipoCalificacion],
  );

  const handleSave = (row: any) => {
    const result = {
      calificaciones: row.calificaciones.map((cal: IStudentGrades & { calificacion: number }) => ({
        ...cal,
        calificacion: row[cal.actividadId] ?? cal.calificacion,
      })),
    };
    const tempArr = calificaciones.map((cal) =>
      cal.estudianteId !== row?.estudianteId
        ? cal
        : {
            calificaciones: result.calificaciones,
            estudianteNombre: row.estudianteNombre,
            estudianteId: row.estudianteId,
            totalAcumulado: row.totalAcumulado,
            totalProyectado: row.totalProyectado,
          },
    );
    setCalificaciones(tempArr);
  };

  const confirm = (value: number | string) => {
    modal.confirm({
      title: `¿Seguro(a) que desea cambiar el modo de calificación?`,
      icon: <Icon name="" color="#FFC53D" />,
      content: `Las calificaciones sin guardar se perderán.`,
      okText: "Aceptar",
      onOk: () => {
        setTipoCalificacion(value);
        setIsInputTouched(false);
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  const columns = defaultColumns.map((col: any) => {
    return {
      ...col,
      onCell: (record: any) => {
        const rec = record?.calificaciones.find((cal: any) => cal?.actividadId == col?.dataIndex);

        return {
          record: {
            ...record,
            [`${col?.dataIndex}`]: rec?.calificacion,
          },
          onchange: () => setIsInputTouched(true),
          max: rec?.puntosPosibles,
          fieldType: col?.fieldType ?? "number",
          options: col?.options,
          editable:
            col?.editable && !data?.info?.estaCursoFinalizado && data?.info?.estaCursoPreparado,
          dataIndex: col?.dataIndex,
          title: col?.title,
          handleSave,
        };
      },
    };
  });

  return (
    <ColumnContainer>
      <SectionHeaderStyled>
        <div>
          <LargeHeadingStyled>Calificaciones del curso {data?.info?.nombre}</LargeHeadingStyled>
          <HeadingSubtitleStyled>
            Sección: {data?.info?.seccion} - Horario:{" "}
            {data?.info?.horario?.map((hor: string) => hor)}
          </HeadingSubtitleStyled>
        </div>
        <Button onClick={() => history.back()} type="default">
          Volver atrás
        </Button>
      </SectionHeaderStyled>
      <SectionFiltersHeaderStyled style={{ alignItems: "end" }}>
        <FlexStyled style={{ gap: 30 }}>
          <Form.Item label="Búsqueda de estudiante por nombre o ID">
            <Input
              allowClear
              value={estudianteNombre ?? undefined}
              onChange={(e) => {
                setEstudianteNombre(e.target.value);
              }}
              placeholder="Búsqueda"
            />
          </Form.Item>
        </FlexStyled>
        <FlexStyled style={{ gap: 15, marginBottom: "20px", flexWrap: "wrap" }}>
          <Button
            type="default"
            loading={isLoadingGradesFile}
            onClick={() => downloadGrades({ seccionId })}
          >
            Exportar registro
          </Button>
          <Button
            type="default"
            onClick={() => navigate(`/teacher/courses/course-grades/${seccionId}/grades-historial`)}
          >
            Ver historial de cambios
          </Button>
          <Segmented
            onChange={(value) => (isInputTouched ? confirm(value) : setTipoCalificacion(value))}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
            value={tipoCalificacion}
            options={[
              {
                value: 1,
                label: "Escala 100",
              },
              {
                value: 2,
                label: "Puntaje",
              },
            ]}
          />
          <Button
            type="primary"
            disabled={data?.info?.estaCursoFinalizado || !data?.info?.estaCursoPreparado}
            loading={isLoading || isLoadingSaving}
            onClick={() => {
              saveGrades({ estudiantes: calificaciones, isScaled: tipoCalificacion === 1 });
            }}
          >
            Guardar
          </Button>
          <ButtonAlternativeStyled
            disabled={!data?.info?.estanTodosCalificados || data?.info?.estaCursoFinalizado}
            loading={isLoading || isLoadingSaving}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            Cerrar acta
          </ButtonAlternativeStyled>
        </FlexStyled>
      </SectionFiltersHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<any>
            components={editableComponents}
            locale={{
              emptyText: (
                <Box>
                  <Box>
                    <SmallHeadingStyled>No se encontraron estudiantes</SmallHeadingStyled>
                    <Image
                      src={NoResultsSVG}
                      preview={false}
                      alt="Ilustración sin resultados"
                      width={200}
                    />
                  </Box>
                </Box>
              ),
            }}
            loading={isLoading}
            columns={columns}
            dataSource={calificaciones}
            rowKey={(v) => v.estudianteId}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
        </Card>
        {contextHolder}
      </Content>
      <GradesPDFPreview
        opened={modalOpened}
        onSuccess={() => obtenerCalificaciones()}
        close={() => setModalOpened(false)}
        seccionId={seccionId}
      />
    </ColumnContainer>
  );
}

export default CourseGrades;
