import React from "react";
import { LargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import {
  DownloadContainerStyled,
  SpinnerContainerStyled,
} from "src/pages/student-pages/cart/cart.styled";
import { Button, Card, Flex, Modal, Spin } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import {
  useGetAdobeFilePreviewMutation,
  useGetAdobeRedirectMutation,
} from "src/services/gradesServices";
import { Icon } from "@nubeteck/components";
import toast from "react-hot-toast";
import { toastSuccessStyle } from "src/constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

function GradesPDFPreview({
  opened,
  close,
  seccionId,
  onSuccess,
}: {
  opened: boolean;
  close: () => void;
  seccionId: string | undefined;
  onSuccess: () => void;
}) {
  const [getAdobePreview, { data: dataPdf, isLoading: isLoadingFile }] =
    useGetAdobeFilePreviewMutation();
  const [openAdobeLink, { isLoading: isLoadingLink, isSuccess, reset }] =
    useGetAdobeRedirectMutation();
  const [PDFPages, setPDFPages] = React.useState<number>(0);
  const [modal, contextHolder] = Modal.useModal();

  React.useEffect(() => {
    if (seccionId && opened) getAdobePreview(seccionId);
  }, [seccionId, opened, getAdobePreview]);
  React.useEffect(() => {
    if (isSuccess) {
      toast(
        "Acta de notas cerrada correctamente. Se le ha enviado un correo para realizar la firma.",
        toastSuccessStyle,
      );
      onSuccess();
      close();
      reset();
    }
  }, [isSuccess, close, onSuccess, reset]);

  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPDFPages(numPages);
  };

  const confirmActa = () => {
    modal.confirm({
      title: `¿Esta seguro(a) de que desea realizar la firma para el cierre de acta?`,
      icon: <Icon name="" color="#FFC53D" />,
      okText: "Cerrar acta",
      onOk: () => {
        openAdobeLink(seccionId);
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  const getPdfPages = () => {
    const pages: JSX.Element[] = [];
    for (let index = 1; index <= PDFPages; index++) {
      pages.push(
        <Page
          pageNumber={index}
          width={window.innerWidth / 2}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderMode="canvas"
        />,
      );
    }
    return pages;
  };

  return (
    <div>
      <Modal
        open={opened}
        closable={false}
        width="55%"
        footer={
          <Flex gap={10}>
            <Button type="primary" onClick={confirmActa} loading={isLoadingLink}>
              Firmar acta
            </Button>
            <Button onClick={close}>Cerrar</Button>
          </Flex>
        }
      >
        <DownloadContainerStyled>
          <Card>
            <LargeHeadingStyled>Acta de calificaciones:</LargeHeadingStyled>
            {isLoadingFile && (
              <SpinnerContainerStyled>
                <Spin spinning={isLoadingFile} size="large" />
              </SpinnerContainerStyled>
            )}
            <Document
              file={`data:application/pdf;base64,${dataPdf}`}
              onLoadSuccess={onDocLoadSuccess}
            >
              {getPdfPages()}
            </Document>
          </Card>
        </DownloadContainerStyled>
      </Modal>
      {contextHolder}
    </div>
  );
}

export default GradesPDFPreview;
