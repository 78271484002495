import { NoResultsSVG } from "src/assets";
import {
  HeadingSubtitleStyled,
  LargeHeadingStyled,
  SectionFiltersHeaderStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "src/components/custom/texts/texts.styled";
import { FlexStyled } from "src/components/registration/register-introduction/register-introduction.styled";
import { IActivityGrades } from "@/Interfaces/teacherCourses";
import { Box, Icon } from "@nubeteck/components";
import { Button, Card, Form, Image, Input, Modal, Segmented } from "antd";
import { Content } from "antd/es/layout/layout";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { SegmentedValue } from "antd/es/segmented";
import { ColumnContainer } from "src/components/layout/main-layout/main-layout.styled";
import {
  useGetGradesByActivityMutation,
  useUpdateGradesByActivityMutation,
} from "src/services/gradesServices";
import { useParams } from "react-router-dom";
import { editableComponents } from "src/components/custom/editable-table-items/editable-table-items";
import { ButtonAlternativeStyled } from "src/components/custom/buttons/buttons.styled";
import GradesPDFPreview from "src/components/other/grades-pdf/gradesPdf";

function ActivityGrades() {
  const { actividadId, seccionId } = useParams();
  const [getGrades, { data, isLoading }] = useGetGradesByActivityMutation();
  const [saveGrades, { isLoading: isLoadingSaving, isSuccess, error }] =
    useUpdateGradesByActivityMutation();
  const [tipoCalificacion, setTipoCalificacion] = React.useState<SegmentedValue>(1);
  const [estudianteNombre, setEstudianteNombre] = useState<string>("");
  const [calificaciones, setCalificaciones] = useState<any[]>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (actividadId && seccionId) getGrades({ actividadId, seccionId });
  }, [getGrades, actividadId, seccionId, isSuccess, tipoCalificacion]);

  useEffect(() => {
    setCalificaciones(data?.calificaciones);
  }, [data]);

  useEffect(() => {
    if (error) toast.error(error?.data?.detail, toastErrorStyle);
    if (isSuccess) {
      toast.success("Calificaciones almacenadas correctamente", toastSuccessStyle);
      setIsInputTouched(false);
    }
  }, [error, isSuccess]);

  const defaultColumns = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "ID",
        dataIndex: "estudianteMatricula",
        render(value) {
          return (
            <p
              style={{
                color: "#00479B",
                fontWeight: "bold",
                margin: 0,
              }}
            >
              {value}
            </p>
          );
        },
      },
      {
        title: "Estudiante",
        dataIndex: "estudianteNombre",
        render(value, record) {
          return (
            <p
              style={{
                color: record.estudianteMatricula ? "#00479B" : "black",
                fontWeight: record.estudianteMatricula ? "bold" : "normal",
                margin: 0,
              }}
            >
              {value}
            </p>
          );
        },
      },
      {
        title: "Puntos obtenidos",
        dataIndex: "puntosObtenidos",
        editable: tipoCalificacion === 2,
        fieldType: "number",
      },
      {
        title: "Puntos posibles",
        dataIndex: "puntosPosibles",
        render: (value) => `/${value}`,
      },
      {
        title: "Calificación (Escala 0 - 100)",
        dataIndex: "calificacionEscala",
        editable: tipoCalificacion === 1,
        fieldType: "number",
      },
      {
        title: "Retroalimentación",
        dataIndex: "feedback",
        editable: true,
        fieldType: "richText",
      },
    ],
    [tipoCalificacion],
  );

  const handleSave = (row: any) => {
    const tempArr = calificaciones.map((cal) =>
      cal.estudianteId !== row?.estudianteId
        ? cal
        : { ...row, calificacionEscala: row.calificacionEscala?.toString() },
    );
    setCalificaciones(tempArr);
  };

  const confirm = (value: number | string) => {
    modal.confirm({
      title: `¿Seguro(a) que desea cambiar el modo de calificación?`,
      icon: <Icon name="" color="#FFC53D" />,
      content: `Las calificaciones sin guardar se perderán.`,
      okText: "Aceptar",
      onOk: () => {
        setTipoCalificacion(value);
        setIsInputTouched(false);
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  const columns = defaultColumns.map((col: any) => {
    return {
      ...col,
      onCell: (record: IActivityGrades) => ({
        record,
        fieldType: col.fieldType,
        options: col.options,
        onchange: () => setIsInputTouched(true),
        editable:
          col.editable &&
          (!data?.info?.estaCursoFinalizado || col.fieldType === "richText") &&
          data?.info?.estaCursoPreparado,
        dataIndex: col.dataIndex,
        title: col.title,
        max: col.dataIndex == "puntosObtenidos" ? record.puntosPosibles : null,
        handleSave,
      }),
    };
  });

  return (
    <ColumnContainer>
      <SectionHeaderStyled>
        <div>
          <LargeHeadingStyled>
            Calificaciones de la actividad {data?.info?.nombre}
          </LargeHeadingStyled>
          <HeadingSubtitleStyled>
            Sección: {data?.info?.seccion} - Horario:{" "}
            {data?.info?.horario?.map((hor: string) => hor)}
          </HeadingSubtitleStyled>
        </div>
        <Button onClick={() => history.back()} type="default">
          Volver atrás
        </Button>
      </SectionHeaderStyled>
      <SectionFiltersHeaderStyled style={{ alignItems: "end", flexWrap: "wrap", height: "65px" }}>
        <FlexStyled style={{ gap: 30 }}>
          <Form.Item label="Búsqueda de estudiante por nombre">
            <Input
              allowClear
              value={estudianteNombre ?? undefined}
              onChange={(e) => {
                setEstudianteNombre(e.target.value);
              }}
              placeholder="Búsqueda"
            />
          </Form.Item>
        </FlexStyled>
        <FlexStyled style={{ gap: 15, marginBottom: "20px", flexWrap: "wrap" }}>
          <Segmented
            onChange={(value) => (isInputTouched ? confirm(value) : setTipoCalificacion(value))}
            value={tipoCalificacion}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
            options={[
              {
                value: 1,
                label: "Escala 100",
              },
              {
                value: 2,
                label: "Puntaje",
              },
            ]}
          />
          {seccionId && (
            <Button
              type="primary"
              disabled={data?.info?.estaCursoFinalizado || !data?.info?.estaCursoPreparado}
              loading={isLoading || isLoadingSaving}
              onClick={() => {
                saveGrades({
                  actividadId,
                  calificaciones,
                  seccionId: +seccionId,
                  isScaled: tipoCalificacion === 1,
                });
              }}
            >
              Guardar
            </Button>
          )}
          <ButtonAlternativeStyled
            disabled={!data?.info?.estanTodosCalificados || data?.info?.estaCursoFinalizado}
            loading={isLoading || isLoadingSaving}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            Cerrar acta
          </ButtonAlternativeStyled>
        </FlexStyled>
      </SectionFiltersHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<any>
            components={calificaciones ? editableComponents : undefined}
            locale={{
              emptyText: (
                <Box>
                  <Box>
                    <SmallHeadingStyled>No se encontraron estudiantes</SmallHeadingStyled>
                    <Image
                      src={NoResultsSVG}
                      preview={false}
                      alt="Ilustración sin resultados"
                      width={200}
                    />
                  </Box>
                </Box>
              ),
            }}
            loading={isLoading}
            columns={columns}
            dataSource={calificaciones}
            rowKey={(v) => `${v.estudianteId}${v.actividadId}`}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
        </Card>
      </Content>
      <GradesPDFPreview
        opened={modalOpened}
        onSuccess={() => getGrades({ actividadId, seccionId })}
        close={() => setModalOpened(false)}
        seccionId={seccionId}
      />{" "}
      {contextHolder}
    </ColumnContainer>
  );
}

export default ActivityGrades;
